import { render } from "./DashboardBuildingSummary.vue?vue&type=template&id=7e6589e9&scoped=true"
import script from "./DashboardBuildingSummary.vue?vue&type=script&lang=js"
export * from "./DashboardBuildingSummary.vue?vue&type=script&lang=js"

import "./DashboardBuildingSummary.vue?vue&type=style&index=0&id=7e6589e9&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-7e6589e9"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7e6589e9"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7e6589e9', script)) {
    api.reload('7e6589e9', script)
  }
  
  module.hot.accept("./DashboardBuildingSummary.vue?vue&type=template&id=7e6589e9&scoped=true", () => {
    api.rerender('7e6589e9', render)
  })

}

script.__file = "src/components/dashboard/DashboardBuildingSummary.vue"

export default script